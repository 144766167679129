navigation = {
    init: function(){
        $('.c-nav-toggle').click(function(e){
            $('body').toggleClass('body-overflow');
            $('.c-subnav-wrapper button').removeClass('c-subnav-active-btn');
            $('.c-nav-subnav').removeClass('c-subnav-active');
        })
        
        $('.c-subnav-wrapper button').click(function(e){
            e.preventDefault();
            if($(this).hasClass('c-subnav-active-btn')){ 
                $('.c-subnav-wrapper button').removeClass('c-subnav-active-btn');
                $('.c-nav-subnav').removeClass('c-subnav-active');
            }else{
                $('.c-subnav-wrapper button').removeClass('c-subnav-active-btn');
                $('.c-nav-subnav').removeClass('c-subnav-active');
                $(this).addClass('c-subnav-active-btn');
                $(this).parent().siblings('.c-nav-subnav').addClass('c-subnav-active');
            }   
        })

        $(window).resize(function(){
            $('body').removeClass('body-overflow');
            $('.c-subnav-wrapper button').removeClass('c-subnav-active-btn');
            $('.c-nav-subnav').removeClass('c-subnav-active');
        })
    }
}