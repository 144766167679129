slider = {
    init: function(){
        if ($('.c-slider-slick').length > 0) {
      
            // fancybox
            $('[data-fancybox="gallery"]').fancybox({
              afterShow: function (instance, slide) {
                
                // Tip: Each event passes useful information within the event object:
                
                // Object containing references to interface elements
                // (background, buttons, caption, etc)
                // console.info( instance.$refs );
                
                // Current slide options
                // console.info( slide.opts );
                
                // Clicked element
                //console.info( slide.opts.$orig );
                
                // Reference to DOM element of the slide
                //console.info( slide.$slide );
                
                let idx = $.fancybox.getInstance().currIndex;
                
                $('.c-slider-slick-for').slick('slickGoTo', idx);
                $('.c-slider-slick-nav').slick('slickGoTo', idx);
                
              },
              thumbs: {
                showOnStart: true
              },
              hash: false
            })
            
            $('.c-slider-slick-for').on('init', function (event, slick) {
              
              if ($('html').hasClass('no-touch')) {
                $('.zoom').ezPlus({
                  responsive: true,
                  easing: true
                });
              }
            })
            
            $('.c-slider-slick-for').slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              fade: false,
              asNavFor: '.c-slider-slick-nav',
              adaptiveHeight: false,
              variableWidth: false,
              centerMode: false,
              dots: false,
              swipeToSlide: true,
              lazyLoad: 'progressive',
              infinite: false,
              nextArrow: '.next-arrow',
              prevArrow: '.prev-arrow',
              responsive: [
                {
                  breakpoint: 960,
                  settings: {
                    arrows: false,
                  }
                },
                {
                  breakpoint: 640,
                  settings: {
                    arrows: true
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: true
                  }
                }
              ]
            });
            
            $('.c-slider-slick-nav').slick({
              slidesToShow: 5,
              slidesToScroll: 1,
              asNavFor: '.c-slider-slick-for',
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              arrows: false,
              adaptiveHeight: false,
              variableWidth: false,
              infinite: false,
              nextArrow: '.next-arrow',
              prevArrow: '.prev-arrow',
            });
            
            $('.next-arrow').on('click', function () {
              if ($('.c-slider-slick-nav .slick-slide').length <= 5) {
            
                var currSlide = $('.c-slider-slick-nav').slick('slickCurrentSlide');
                $('.c-slider-slick-nav').slick('slickGoTo', currSlide + 1, 'false');
                $('.c-slider-slick-nav .slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
              } else {
                $('.c-slider-slick-nav').slick('slickNext');
              }
            });
            $('.prev-arrow').on('click', function () {
              if ($('.c-slider-slick-nav .slick-slide').length <= 5) {
                var currSlide = $('.c-slider-slick-nav').slick('slickCurrentSlide');
                $('.c-slider-slick-nav').slick('slickGoTo', currSlide - 1, 'false');
                $('.c-slider-slick-nav .slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
              } else {
                $('.c-slider-slick-nav').slick('slickPrev');
              }
            });
            
          }
    }
}