cart = {
    init:function(){

        $('input[name=delivery]').change(function(){
            var checked = $(this).attr('checked', true).val();
            if($(this).hasClass('delivery')){
                $('#cart-delivery').addClass('visible');
            }else{
                $('#cart-delivery').removeClass('visible');
            }
        })

        $('.c-cart-amount').change(function(){
            var amount = this.value;
            var pricePerPiece = $(this).parent().parent().find('.c-cart-price-piece span').html();
            pricePerPiece = Number(pricePerPiece.replace(',','.'));
            var newPrice = Number(amount) * Number(pricePerPiece);
            var subTotal = $(this).parent().parent().find('.c-cart-price-subtotal span').html(newPrice);
        })
    }
}