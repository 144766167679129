accordion = {
    init: function () {
        $('.c-faq-item-title').click(function(e) {
            e.preventDefault();
          let $this = $(this);
          if ($this.next().hasClass('c-faq-item-active')) {
              $this.next().removeClass('c-faq-item-active');
              $this.removeClass('c-faq-item-active');
              $this.next().slideUp(350);
          } else {
              $('.c-faq-item-content').removeClass('c-faq-item-active');
              $('.c-faq-item-title').removeClass('c-faq-item-active');
              $('.c-faq-item-content').slideUp(350);
              $this.next().toggleClass('c-faq-item-active');
              $this.toggleClass('c-faq-item-active');
              $this.next().slideToggle(350);
          }
      });
    }
}